// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as LanguageConfig$FrontendWebsite from "../LanguageConfig.bs.js";

var DhakaEnglish_canter_3tonne_title = "Hire 12 Feet Pickup Trucks in Dhaka with Ease";

var DhakaEnglish_canter_3tonne_meta_description = "Get affordable truck rental services and online truck booking from Porter and access hassle-free logistics in Dhaka.";

var DhakaEnglish_two_wheeler_title = "Affordable On-demand Bike Delivery Service in Dhaka";

var DhakaEnglish_two_wheeler_meta_description = "Send packages less than 20 kg using Porter's bike same-day delivery service and experience hassle-free logistics!";

var DhakaEnglish_pickup_1tonne_title = "Book Reliable 1 Ton 7 Feet Pickup Trucks in Dhaka";

var DhakaEnglish_pickup_1tonne_meta_description = "Rent cost-effective pickup trucks from Porter, perfect for transporting anything, anytime, anywhere in Dhaka.";

var DhakaEnglish_compact_title = "";

var DhakaEnglish_compact_meta_description = "";

var DhakaEnglish_courier_title = "";

var DhakaEnglish_courier_meta_description = "";

var DhakaEnglish = {
  canter_3tonne_title: DhakaEnglish_canter_3tonne_title,
  canter_3tonne_meta_description: DhakaEnglish_canter_3tonne_meta_description,
  two_wheeler_title: DhakaEnglish_two_wheeler_title,
  two_wheeler_meta_description: DhakaEnglish_two_wheeler_meta_description,
  pickup_1tonne_title: DhakaEnglish_pickup_1tonne_title,
  pickup_1tonne_meta_description: DhakaEnglish_pickup_1tonne_meta_description,
  compact_title: DhakaEnglish_compact_title,
  compact_meta_description: DhakaEnglish_compact_meta_description,
  courier_title: DhakaEnglish_courier_title,
  courier_meta_description: DhakaEnglish_courier_meta_description
};

var DhakaBD_canter_3tonne_title = "সহজেই ঢাকায় 12 পা দুটো পিক-আপ ট্রাক ভাড়া করুন";

var DhakaBD_canter_3tonne_meta_description = "Porter থেকে সাশ্রয়ী মূল্যের ট্রাক ভাড়া পরিষেবা এবং অনলাইন ট্রাক বুকিং পান এবং ঢাকায় ঝামেলামুক্ত লজিস্টিক অ্যাক্সেস করুন।";

var DhakaBD_two_wheeler_title = "ঢাকায় সাশ্রয়ী মূল্যের অন-ডিমান্ড বাইক ডেলিভারি সার্ভিস";

var DhakaBD_two_wheeler_meta_description = "Porter-এর বাইক একই দিনে ডেলিভারি সার্ভিস ব্যবহার করে 20 কেজিরও কম প্যাকেজ প্রেরণ করুন এবং ঝামেলামুক্ত লজিস্টিকের অভিজ্ঞতা নিন!";

var DhakaBD_pickup_1tonne_title = "ঢাকায় নির্ভরযোগ্য 1 টনের 7 পা দুটো পিক-আপ ট্রাক বুক করুন";

var DhakaBD_pickup_1tonne_meta_description = "Porter থেকে সাশ্রয়ী মূল্যের পিক-আপ ট্রাক ভাড়া করুন, যেকোনো সময়, যেকোনো কিছু, ঢাকার যেকোনো জায়গায় পরিবহনের জন্য উপযুক্ত।";

var DhakaBD_compact_title = "";

var DhakaBD_compact_meta_description = "";

var DhakaBD_courier_title = "";

var DhakaBD_courier_meta_description = "";

var DhakaBD = {
  canter_3tonne_title: DhakaBD_canter_3tonne_title,
  canter_3tonne_meta_description: DhakaBD_canter_3tonne_meta_description,
  two_wheeler_title: DhakaBD_two_wheeler_title,
  two_wheeler_meta_description: DhakaBD_two_wheeler_meta_description,
  pickup_1tonne_title: DhakaBD_pickup_1tonne_title,
  pickup_1tonne_meta_description: DhakaBD_pickup_1tonne_meta_description,
  compact_title: DhakaBD_compact_title,
  compact_meta_description: DhakaBD_compact_meta_description,
  courier_title: DhakaBD_courier_title,
  courier_meta_description: DhakaBD_courier_meta_description
};

function getVehicleData(cities) {
  return DhakaEnglish;
}

function canter_3tonne_title(name, city) {
  return "Reliable Truck " + name + " Rentals in " + $$String.capitalize_ascii(city);
}

function pickup_1tonne_title(name, city) {
  return "Rent Affordable " + name + " Pickup Trucks in " + $$String.capitalize_ascii(city);
}

function two_wheeler_title(name, city) {
  return "Quick & On-time Bike Delivery Service in " + $$String.capitalize_ascii(city);
}

var English_porter_offers_sub_title = "Exciting offers coming soon";

var English = {
  getVehicleData: getVehicleData,
  canter_3tonne_title: canter_3tonne_title,
  pickup_1tonne_title: pickup_1tonne_title,
  two_wheeler_title: two_wheeler_title,
  porter_offers_sub_title: English_porter_offers_sub_title
};

function getVehicleData$1(cities) {
  return DhakaBD;
}

function canter_3tonne_title$1(name, city) {
  return LanguageConfig$FrontendWebsite.convertCityFronEnglishToBangla(city) + "য় নির্ভরযোগ্য " + name + " ট্রাক ভাড়া";
}

function two_wheeler_title$1(name, city) {
  return LanguageConfig$FrontendWebsite.convertCityFronEnglishToBangla(city) + "য় কুইক অ্যান্ড অন-টাইম বাইক ডেলিভারি সার্ভিস";
}

function pickup_1tonne_title$1(name, city) {
  return LanguageConfig$FrontendWebsite.convertCityFronEnglishToBangla(city) + "য় ঢাকায় সাশ্রয়ী মূল্যের " + name + " পিক-আপ ট্রাক ভাড়া করুন";
}

var BD_porter_offers_sub_title = "আকর্ষণীয় অফার শীঘ্রই আসছে";

var BD = {
  getVehicleData: getVehicleData$1,
  canter_3tonne_title: canter_3tonne_title$1,
  two_wheeler_title: two_wheeler_title$1,
  pickup_1tonne_title: pickup_1tonne_title$1,
  porter_offers_sub_title: BD_porter_offers_sub_title
};

export {
  DhakaEnglish ,
  DhakaBD ,
  English ,
  BD ,
  
}
/* LanguageConfig-FrontendWebsite Not a pure module */
