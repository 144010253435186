// Generated by ReScript, PLEASE EDIT WITH CARE


var vehicles_available_dubai = {
  hd: {
    TAG: /* UAE */0,
    _0: /* Canter3Tonne */1
  },
  tl: {
    hd: {
      TAG: /* UAE */0,
      _0: /* TwoWheeler */0
    },
    tl: {
      hd: {
        TAG: /* UAE */0,
        _0: /* Pickup1Tonne */2
      },
      tl: {
        hd: {
          TAG: /* UAE */0,
          _0: /* Compact */4
        },
        tl: {
          hd: {
            TAG: /* UAE */0,
            _0: /* Courier */3
          },
          tl: /* [] */0
        }
      }
    }
  }
};

var vehicles_available_sharjah = {
  hd: {
    TAG: /* UAE */0,
    _0: /* Canter3Tonne */1
  },
  tl: {
    hd: {
      TAG: /* UAE */0,
      _0: /* TwoWheeler */0
    },
    tl: {
      hd: {
        TAG: /* UAE */0,
        _0: /* Pickup1Tonne */2
      },
      tl: {
        hd: {
          TAG: /* UAE */0,
          _0: /* Compact */4
        },
        tl: {
          hd: {
            TAG: /* UAE */0,
            _0: /* Courier */3
          },
          tl: /* [] */0
        }
      }
    }
  }
};

var vehicles_available_dhaka = {
  hd: {
    TAG: /* BD */1,
    _0: /* Pickup1Tonne */0
  },
  tl: {
    hd: {
      TAG: /* BD */1,
      _0: /* TwoWheeler */1
    },
    tl: /* [] */0
  }
};

var vehicles_available_istanbul = {
  hd: {
    TAG: /* TR */2,
    _0: /* Pickup1Tonne */0
  },
  tl: /* [] */0
};

export {
  vehicles_available_dubai ,
  vehicles_available_sharjah ,
  vehicles_available_dhaka ,
  vehicles_available_istanbul ,
  
}
/* No side effect */
