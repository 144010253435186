// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as UrlUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/UrlUtils.bs.js";
import * as ErrorUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/ErrorUtils.bs.js";
import * as VehicleType$FrontendWebsite from "../vehicle/VehicleType.bs.js";
import * as CompactRoutes$FrontendWebsite from "./CompactRoutes.bs.js";
import * as RoutingHelper$FrontendWebsite from "./RoutingHelper.bs.js";

function getRoute(routeUrl, country) {
  var match = routeUrl.path;
  if (!match) {
    return /* Home */1;
  }
  switch (match.hd) {
    case "about-us" :
        if (!match.tl) {
          return /* AboutUs */3;
        }
        break;
    case "compact-dubai" :
        if (!match.tl) {
          return CompactRoutes$FrontendWebsite.fromRouteString("compact-dubai", country);
        }
        break;
    case "compact-sharjah" :
        if (!match.tl) {
          return CompactRoutes$FrontendWebsite.fromRouteString("compact-sharjah", country);
        }
        break;
    case "courier" :
        var match$1 = match.tl;
        if (!match$1) {
          return /* CourierPage */11;
        }
        if (!match$1.tl) {
          var vehicle = VehicleType$FrontendWebsite.fromString("Courier", country);
          return {
                  TAG: /* VehiclePage */1,
                  _0: match$1.hd,
                  _1: vehicle
                };
        }
        break;
    case "dubai-branch" :
        var match$2 = match.tl;
        if (match$2 && match$2.hd === "terms-of-service" && !match$2.tl) {
          return /* TermsOfServiceDubaiBranch */5;
        }
        break;
    case "fare_estimate_mob" :
        if (!match.tl) {
          var urlParams = UrlUtils$Catalyst.getURLParams(routeUrl.search);
          var geo_region_id = Js_dict.get(urlParams, "geo_region_id");
          var from_address_landmark = Js_dict.get(urlParams, "from_address_landmark");
          var to_address_landmark = Js_dict.get(urlParams, "to_address_landmark");
          var from_address_lat = Js_dict.get(urlParams, "from_address_lat");
          var from_address_lng = Js_dict.get(urlParams, "from_address_long");
          var to_address_lat = Js_dict.get(urlParams, "to_address_lat");
          var to_address_lng = Js_dict.get(urlParams, "to_address_long");
          var customer_name = Js_dict.get(urlParams, "customer_name");
          var customer_mobile = Js_dict.get(urlParams, "customer_mobile");
          var frequency = Js_dict.get(urlParams, "frequency");
          var fare_estimate_token = Js_dict.get(urlParams, "fare_estimate_token");
          var consent_for_callback = Js_dict.get(urlParams, "consent_for_callback");
          var fare_estimate_form_data = RoutingHelper$FrontendWebsite.getFareEstimateFormData(geo_region_id, from_address_landmark, to_address_landmark, from_address_lat, from_address_lng, to_address_lat, to_address_lng, customer_name, customer_mobile, frequency, fare_estimate_token, consent_for_callback);
          return {
                  TAG: /* FareEstimateResult */4,
                  _0: fare_estimate_form_data
                };
        }
        break;
    case "offers" :
        var match$3 = match.tl;
        if (match$3 && match$3.hd === "discount") {
          var match$4 = match$3.tl;
          if (match$4 && !match$4.tl) {
            return {
                    TAG: /* OffersPage */5,
                    discount_id: match$4.hd
                  };
          }
          
        }
        break;
    case "partners" :
        if (!match.tl) {
          return /* DriverPartners */2;
        }
        break;
    case "privacy-policy" :
        if (!match.tl) {
          return {
                  TAG: /* PrivacyPolicy */3,
                  _0: undefined
                };
        }
        break;
    case "privacy-policy-v1" :
        if (!match.tl) {
          return {
                  TAG: /* PrivacyPolicy */3,
                  _0: "v1"
                };
        }
        break;
    case "refund-policy" :
        if (!match.tl) {
          return /* RefundPolicy */7;
        }
        break;
    case "services" :
        var match$5 = match.tl;
        if (!match$5) {
          return /* ServicesPage */10;
        }
        if (!match$5.tl) {
          return {
                  TAG: /* KeywordPage */6,
                  _0: match$5.hd
                };
        }
        break;
    case "spot-faq" :
        if (!match.tl) {
          return /* SpotFaq */8;
        }
        break;
    case "support" :
        if (!match.tl) {
          return /* Support */9;
        }
        break;
    case "terms-and-conditions" :
        if (!match.tl) {
          return /* TermsOfService */4;
        }
        break;
    case "terms-of-service" :
        var match$6 = match.tl;
        if (!match$6) {
          return /* TermsOfService */4;
        }
        if (match$6.hd === "partners" && !match$6.tl) {
          return /* PartnerTermsOfService */6;
        }
        break;
    case "track_live_order" :
        if (!match.tl) {
          var urlParams$1 = UrlUtils$Catalyst.getURLParams(routeUrl.search);
          var match$7 = Js_dict.get(urlParams$1, "booking_id");
          var match$8 = Js_dict.get(urlParams$1, "customer_uuid");
          if (match$7 !== undefined && match$8 !== undefined) {
            return {
                    TAG: /* TrackLiveTrip */2,
                    _0: match$7,
                    _1: match$8
                  };
          } else {
            return ErrorUtils$Catalyst.raiseError("WebsiteRouteManager.re", "Invalid booking_id/customer_uuid", "");
          }
        }
        break;
    default:
      
  }
  return RoutingHelper$FrontendWebsite.getPageRoutes({
              hd: (function (param) {
                  return RoutingHelper$FrontendWebsite.getCityRoutes(routeUrl.path);
                }),
              tl: {
                hd: (function (param) {
                    return RoutingHelper$FrontendWebsite.getVehiclesRoutes(routeUrl.path, country);
                  }),
                tl: {
                  hd: (function (param) {
                      return RoutingHelper$FrontendWebsite.getCityRoutes(routeUrl.path);
                    }),
                  tl: /* [] */0
                }
              }
            });
}

export {
  getRoute ,
  
}
/* RoutingHelper-FrontendWebsite Not a pure module */
