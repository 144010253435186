// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../node_modules/rescript/lib/es6/js_json.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as CookieUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/CookieUtils.bs.js";
import * as CountryConfig$FrontendWebsite from "../../app/CountryConfig.bs.js";

function languageToString(language) {
  switch (language.TAG | 0) {
    case /* UAE */0 :
        return "en";
    case /* BD */1 :
        if (language._0) {
          return "bn";
        } else {
          return "en";
        }
    case /* TR */2 :
        if (language._0) {
          return "tr";
        } else {
          return "en";
        }
    
  }
}

function stringToLanguage(lang) {
  switch (lang) {
    case "bd" :
        return {
                TAG: /* BD */1,
                _0: /* BD */1
              };
    case "en_bd" :
        return {
                TAG: /* BD */1,
                _0: /* En_BD */0
              };
    case "en_tr" :
        return {
                TAG: /* TR */2,
                _0: /* En_TR */0
              };
    case "en_uae" :
        return {
                TAG: /* UAE */0,
                _0: /* En_UAE */0
              };
    case "tr" :
        return {
                TAG: /* TR */2,
                _0: /* TR */1
              };
    default:
      return {
              TAG: /* UAE */0,
              _0: /* En_UAE */0
            };
  }
}

function getBDDefaultLanguage(param) {
  var selected_language = Js_json.decodeObject(CookieUtils$Catalyst.getCookie("selected_language"));
  if (selected_language === undefined) {
    return {
            TAG: /* BD */1,
            _0: /* BD */1
          };
  }
  var value = Js_dict.get(Caml_option.valFromOption(selected_language), "selected_language");
  if (value === undefined) {
    return {
            TAG: /* BD */1,
            _0: /* BD */1
          };
  }
  var match = String(Caml_option.valFromOption(value));
  switch (match) {
    case "bd" :
        return {
                TAG: /* BD */1,
                _0: /* BD */1
              };
    case "english" :
        return {
                TAG: /* BD */1,
                _0: /* En_BD */0
              };
    default:
      return {
              TAG: /* BD */1,
              _0: /* BD */1
            };
  }
}

function getTRDefaultLanguage(param) {
  var selected_language = Js_json.decodeObject(CookieUtils$Catalyst.getCookie("selected_language"));
  if (selected_language === undefined) {
    return {
            TAG: /* TR */2,
            _0: /* En_TR */0
          };
  }
  var value = Js_dict.get(Caml_option.valFromOption(selected_language), "selected_language");
  if (value === undefined) {
    return {
            TAG: /* TR */2,
            _0: /* En_TR */0
          };
  }
  var match = String(Caml_option.valFromOption(value));
  switch (match) {
    case "english" :
        return {
                TAG: /* TR */2,
                _0: /* En_TR */0
              };
    case "tr" :
        return {
                TAG: /* TR */2,
                _0: /* TR */1
              };
    default:
      return {
              TAG: /* TR */2,
              _0: /* En_TR */0
            };
  }
}

function getLanguage(param) {
  var country = CountryConfig$FrontendWebsite.getCountryConfig(undefined);
  switch (country.TAG | 0) {
    case /* UAE */0 :
        return {
                TAG: /* UAE */0,
                _0: /* En_UAE */0
              };
    case /* BD */1 :
        return getBDDefaultLanguage(undefined);
    case /* TR */2 :
        return getTRDefaultLanguage(undefined);
    
  }
}

function convertCityFronEnglishToBangla(city) {
  var match = city.toLowerCase();
  if (match === "dhaka") {
    return "ঢাকা";
  } else {
    return "ঢাকা";
  }
}

function convertCityFronEnglishToTurkish(city) {
  var match = city.toLowerCase();
  if (match === "istanbul") {
    return "istanbul";
  } else {
    return "istanbul";
  }
}

function getLocaleFromLanguage(language) {
  switch (language.TAG | 0) {
    case /* UAE */0 :
        return "en";
    case /* BD */1 :
        if (language._0) {
          return "bn-BD";
        } else {
          return "en";
        }
    case /* TR */2 :
        if (language._0) {
          return "tr-TR";
        } else {
          return "en";
        }
    
  }
}

var Locale = {
  getLocaleFromLanguage: getLocaleFromLanguage
};

export {
  languageToString ,
  stringToLanguage ,
  getBDDefaultLanguage ,
  getTRDefaultLanguage ,
  getLanguage ,
  convertCityFronEnglishToBangla ,
  convertCityFronEnglishToTurkish ,
  Locale ,
  
}
/* CookieUtils-Catalyst Not a pure module */
