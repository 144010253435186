// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../utils/NetworkUtils.bs.js";
import * as TrackLiveTripRepoConfig$FrontendWebsite from "./TrackLiveTripRepoConfig.bs.js";

var dummyTrackLiveTripFormSubmissionResponse = {
        "redirect_path" : "https://crm-staging-2.porter.ae/track_live_order?booking_id=CRN23089112&customer_uuid=1674668b-d430-4895-8cd8-dbbce7753cb9",
      };

var dummyTrackLiveTripInfo = {
        "order_details": {
          "order_id": "CRN59849505",
          "reallocated_order_id": null,
          "status": "open",
          "payment_mode": "cash",
          "is_postpaid": true,
          "has_toll": false,
          "distance": 17.89,
          "previous_due": 0.0,
          "total_discount": 0.0,
          "pickup_time": 1600222613,
          "toll_amount": 0.0,
          "customer_info": {
            "customer_id": 2160412,
            "customer_mobile": "9769547707",
            "name": "Nashir n customer",
          },
          "booking_number": "9769547707",
          "business_type": "Other essential goods",
          "create_trip_fare_strategy": "fixed_pricing",
          "estimated_trip_fare": 649,
          "estimated_partner_trip_fare": 649,
          "vehicle": {
            "icons": {
                "map_marker": "https://porter-customer-app-promo-images-prod.s3.amazonaws.com/Map+icon+front+view/4wheeler_front-xxxhdpi.png",
                "selected": "https://porter-vehicles.s3-ap-southeast-1.amazonaws.com/icon-images/tata_ace_filled.png",
                "deselected": "https://porter-vehicles.s3-ap-southeast-1.amazonaws.com/icon-images/tata_ace_outlined.png"
            },
            "vehicle_url" : "https://img.favpng.com/11/22/9/car-truck-commercial-vehicle-icon-png-favpng-gPBXwGHW6JDUEduF8ggwnH0XG.jpg",
            "vehicle_type": "2 wheeler",
            "vehicle_id": 1,
            "size": "7ft x 4.5ft x 5.5ft",
            "capacity": "750 kg",
            "free_waiting_mins": 70,
            "travel_rate_per_min": 3.0,
            "waiting_rate_per_min": 3.5,
          },
          "pickup_info": {
            "place": {
              "landmark": "8, 90 Feet Rd, Krishna Nagar, Sathi D Souza Nagar, Saki Naka, Mumbai, Maharashtra 400072, India",
              "location": {
                "lat": 19.0972510636143,
                "long": 72.8881760314107,
              },
            },
            "contact": {
              "name": "Nashir Pickup Name",
              "number": "9769547707",
            },
          },
          "has_waypoints": true,
          "waypoints": [
             {
               "place": {
                 "location": {
                   "lat": 19.295713,
                   "long": 72.848410,
                 },
                 "landmark": "Concorde Silicon Valley, Electronics City Phase 1, Electronic City, Karnataka, India Concorde Silicon Valley, Electronics City Phase 1, Electronic City, Karnataka, India Concorde Silicon Valley, Electronics City Phase 1, Electronic City, Karnataka, India Concorde Silicon Valley, Electronics City Phase 1, Electronic City, Karnataka, India",
               },
               "contact": {
                 "name": "atul kamath",
                 "number": "9731592801",
               },
             },
             {
               "place": {
                 "location": {
                   "lat": 29.3482510,
                   "long": 85.798176,
                 },
                 "landmark": "Concorde Silicon Valley, Electronics City Phase 1, Electronic City, Karnataka, India",
               },
               "contact": {
                 "name": "atul kamath",
                 "number": "9731592801",
               },
             },
          ],
          "order_type": "rental",
          "geo_region_id": 1,
          "is_helper_order": true,
          "is_outstation_order": false,
          "partner_info": {
            "name": "Mohd Salim Mohammed Ali Nashir Shah",
            "vehicle_number": "MH-03-CV-2956",
            "vehicle_type": "2 Wheeler",
            "mobile": "9819474966",
            "partner_secondary_mobile": "9137096004",
            "driver_msisdn": "9819474966956",
            "has_nep": false,
            "fuel_type": "unknown",
          },
          "drop_info": {
            "place": {
              "landmark": "JJ Hospital Mumbai, Mohammed Ali Road, Mazgaon, Mumbai, Maharashtra, India",
              "location": {
                "lat": 19.0366733,
                "long": 72.8327624,
              },
            },
            "contact": {
              "name": "Nashir n",
              "number": "9769547707",
            },
          },
          "trip_accepted_time": 1600222072,
          "trip_accepted_location": "POINT (72.8856163 19.094678)",
          "trip_ended_time": 1759080083,
          "partner_location": {
            "lat": 19.0946458,
            "long": 72.8856103,
          },
          "trip_started_time": 1658749111,
        },
        "config": {
          "customer_care_number": "2244104410",
        },
      };

var dummyMarketingInfo = { "marketing_info": {
        "savings_amount": 11.5,
        "average_eta": 1270,
        "registered_partners": 888,
        "average_booking_time": 30,
        "download_app_link":"https://oms-staging-1.porter.ae/customerapplinks/registration_via_track_live?referral_code=AKA6MD\u0026referrer=8a2995a3-ebf8-4264-a85d-6e2c51d64659\u0026order_id=CRN79966950",
        "referral_discount_info": {
          "discount_amount": 200,
          "n_tickets": 2,
          "discount_code": "WOWOWOW",
        },
        "coupon_discount_info":{
          "discount_amount": 200,
          "n_tickets": 2,
          "discount_code": "WOWOWOW",
        },
      }};

function submitForm(_form, _country, _language) {
  return NetworkUtils$FrontendWebsite.catchPromiseError(new Promise((function (resolve, param) {
                      return resolve({
                                  TAG: /* Success */0,
                                  _0: dummyTrackLiveTripFormSubmissionResponse
                                });
                    })).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(TrackLiveTripRepoConfig$FrontendWebsite.PostApiResponseConverter.execute, result);
                            });
                }), "submitForm");
}

function fetchTrackLiveTripInfo(_booking_id, _customer_uuid, _country, _language) {
  return NetworkUtils$FrontendWebsite.catchPromiseError(new Promise((function (resolve, param) {
                      return resolve({
                                  TAG: /* Success */0,
                                  _0: dummyTrackLiveTripInfo
                                });
                    })).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(TrackLiveTripRepoConfig$FrontendWebsite.TrackLiveTripResponseConverter.execute, result);
                            });
                }), "fetchTrackLiveTripInfo");
}

function fetchTrackLiveTripMarketingInfo(_booking_id, _customer_uuid, _country, _language) {
  return NetworkUtils$FrontendWebsite.catchPromiseError(new Promise((function (resolve, param) {
                      return resolve({
                                  TAG: /* Success */0,
                                  _0: dummyMarketingInfo
                                });
                    })).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(TrackLiveTripRepoConfig$FrontendWebsite.TrackLiveTripPromoResponseConverter.execute, result);
                            });
                }), "fetchTrackLiveTripMarketingInfo");
}

export {
  dummyTrackLiveTripFormSubmissionResponse ,
  dummyTrackLiveTripInfo ,
  dummyMarketingInfo ,
  submitForm ,
  fetchTrackLiveTripInfo ,
  fetchTrackLiveTripMarketingInfo ,
  
}
/* NetworkUtils-FrontendWebsite Not a pure module */
