// Generated by ReScript, PLEASE EDIT WITH CARE


var UAE = {};

var BD = {};

var TR = {};

function toString(vehicle) {
  switch (vehicle.TAG | 0) {
    case /* UAE */0 :
        switch (vehicle._0) {
          case /* TwoWheeler */0 :
              return "2 Wheeler";
          case /* Canter3Tonne */1 :
              return "Canter - 3 Tonne";
          case /* Pickup1Tonne */2 :
              return "Pickup - 1 Tonne";
          case /* Courier */3 :
              return "Courier";
          case /* Compact */4 :
              return "Compact";
          
        }
    case /* BD */1 :
        if (vehicle._0) {
          return "2 Wheeler";
        } else {
          return "1 Ton 7 Feet";
        }
    case /* TR */2 :
        return "Doblo";
    
  }
}

function fromString(vehicle_display_name, country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        var match = vehicle_display_name.trim();
        switch (match) {
          case "2 Wheeler" :
          case "2 Wheeler-Outstation" :
              return {
                      TAG: /* UAE */0,
                      _0: /* TwoWheeler */0
                    };
          case "Canter - 3 Tonne" :
          case "Canter 3Ton" :
              return {
                      TAG: /* UAE */0,
                      _0: /* Canter3Tonne */1
                    };
          case "Compact" :
              return {
                      TAG: /* UAE */0,
                      _0: /* Compact */4
                    };
          case "Courier" :
              return {
                      TAG: /* UAE */0,
                      _0: /* Courier */3
                    };
          case "Pickup - 1 Tonne" :
          case "Pickup 1Ton" :
              return {
                      TAG: /* UAE */0,
                      _0: /* Pickup1Tonne */2
                    };
          default:
            return {
                    TAG: /* UAE */0,
                    _0: /* Canter3Tonne */1
                  };
        }
    case /* BD */1 :
        var match$1 = vehicle_display_name.trim();
        switch (match$1) {
          case "1 Ton 7 Feet" :
              return {
                      TAG: /* BD */1,
                      _0: /* Pickup1Tonne */0
                    };
          case "2 Wheeler" :
              return {
                      TAG: /* BD */1,
                      _0: /* TwoWheeler */1
                    };
          default:
            return {
                    TAG: /* BD */1,
                    _0: /* Pickup1Tonne */0
                  };
        }
    case /* TR */2 :
        vehicle_display_name.trim();
        return {
                TAG: /* TR */2,
                _0: /* Pickup1Tonne */0
              };
    
  }
}

export {
  UAE ,
  BD ,
  TR ,
  toString ,
  fromString ,
  
}
/* No side effect */
