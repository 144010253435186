// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../node_modules/rescript/lib/es6/array.js";
import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_array from "../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as RescriptReactRouter from "../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as GAEvents$FrontendWebsite from "../../utils/library/ga/GAEvents.bs.js";
import * as OptionTypeUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/OptionTypeUtils.bs.js";
import * as UtmUtils$FrontendWebsite from "../../utils/UtmUtils.bs.js";
import * as RoutesConfig$FrontendWebsite from "./RoutesConfig.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../website/types/WebsiteTypes.bs.js";
import * as AssetsManager$FrontendWebsite from "../../view/assets/AssetsManager.bs.js";
import * as QueryParamsConstructor$Catalyst from "../../../node_modules/@porterin/catalyst/src/helper-modules/query-params/QueryParamsConstructor.bs.js";

var headerAssets = AssetsManager$FrontendWebsite.getCommonImage("header_assets.svg");

var uiAssets = AssetsManager$FrontendWebsite.getCommonImage("ui_assets.svg");

var driverIcon = AssetsManager$FrontendWebsite.getCommonImage("driver_icon.svg");

function isd_code(country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        return "+971";
    case /* BD */1 :
        return "+880";
    case /* TR */2 :
        return "+90";
    
  }
}

function publishStickyGAEvents(action, labelOpt, categoryOpt, language, param) {
  var label = labelOpt !== undefined ? labelOpt : "";
  var category = categoryOpt !== undefined ? categoryOpt : "";
  return GAEvents$FrontendWebsite.publishEventParameter(action, {
              event_category: category,
              event_label: label,
              language_selected: language
            });
}

function get_utm_param_with_value(param_value) {
  var url = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
  var utm_obj = UtmUtils$FrontendWebsite.getAllUtmParams(url);
  $$Array.iteri((function (index, param) {
          var param_value_status = OptionTypeUtils$Catalyst.getEmptyStringFrmOptionalType(Js_dict.get(utm_obj, param));
          if (param_value_status.length === 0) {
            utm_obj[param] = Caml_array.get(param_value, index);
            return ;
          }
          
        }), UtmUtils$FrontendWebsite.utm_params_keys);
  utm_obj["feature_source"] = Caml_array.get(param_value, param_value.length - 1 | 0);
  return Belt_Option.getWithDefault(QueryParamsConstructor$Catalyst.create(utm_obj), "");
}

function cityTempoInfo(title) {
  return {
          TAG: /* LinkText */0,
          _0: {
            text: WebsiteTypes$FrontendWebsite.createTitle(title),
            target: WebsiteTypes$FrontendWebsite.createUrl(RoutesConfig$FrontendWebsite.homeUrl)
          }
        };
}

function driverPartnersInfo(title) {
  return {
          TAG: /* LinkText */0,
          _0: {
            text: WebsiteTypes$FrontendWebsite.createTitle(title),
            target: WebsiteTypes$FrontendWebsite.createUrl(RoutesConfig$FrontendWebsite.partnersUrl)
          }
        };
}

function supportInfo(title) {
  return {
          TAG: /* LinkText */0,
          _0: {
            text: WebsiteTypes$FrontendWebsite.createTitle(title),
            target: WebsiteTypes$FrontendWebsite.createUrl(RoutesConfig$FrontendWebsite.supportUrl)
          }
        };
}

function aboutUsInfo(label) {
  return {
          TAG: /* LinkText */0,
          _0: {
            text: WebsiteTypes$FrontendWebsite.createTitle(label),
            target: WebsiteTypes$FrontendWebsite.createUrl(RoutesConfig$FrontendWebsite.aboutUsUrl)
          }
        };
}

function privacyPolicyInfo(label) {
  return {
          TAG: /* LinkText */0,
          _0: {
            text: WebsiteTypes$FrontendWebsite.createTitle(label),
            target: WebsiteTypes$FrontendWebsite.createUrl(RoutesConfig$FrontendWebsite.privacyPolicyUrl)
          }
        };
}

function refundPolicyInfo(label) {
  return {
          TAG: /* LinkText */0,
          _0: {
            text: WebsiteTypes$FrontendWebsite.createTitle(label),
            target: WebsiteTypes$FrontendWebsite.createUrl(RoutesConfig$FrontendWebsite.refundPolicyUrl)
          }
        };
}

function termsOfServiceInfo(label, url) {
  return {
          TAG: /* LinkText */0,
          _0: {
            text: WebsiteTypes$FrontendWebsite.createTitle(label),
            target: WebsiteTypes$FrontendWebsite.createUrl(url)
          }
        };
}

function driveWithUsInfo(title) {
  return {
          TAG: /* LinkText */0,
          _0: {
            text: WebsiteTypes$FrontendWebsite.createTitle(title),
            target: WebsiteTypes$FrontendWebsite.createUrl(RoutesConfig$FrontendWebsite.partnersUrl)
          }
        };
}

var link404 = {
  TAG: /* LinkText */0,
  _0: {
    text: WebsiteTypes$FrontendWebsite.createTitle("Redirect to Home"),
    target: WebsiteTypes$FrontendWebsite.createUrl(RoutesConfig$FrontendWebsite.homeUrl)
  }
};

function Desktop(HomeContent) {
  var joinUsInfo_title = HomeContent.driverPartnerJoinUsTitle;
  var joinUsInfo_description = HomeContent.driverPartnerHeadingDesc;
  var joinUsInfo = {
    title: joinUsInfo_title,
    description: joinUsInfo_description,
    content: /* [] */0
  };
  var driverLogo = {
    TAG: /* LabelledImgWithDesc */6,
    _0: {
      src_path: WebsiteTypes$FrontendWebsite.createAssetPath(driverIcon),
      text: WebsiteTypes$FrontendWebsite.createTitle("Driver Icon"),
      desc: undefined,
      class_name: undefined
    }
  };
  return {
          joinUsInfo: joinUsInfo,
          driverLogo: driverLogo
        };
}

function Mobile(HomeContent) {
  var hamburgerInfo = {
    TAG: /* LabelledImgWithDesc */6,
    _0: {
      src_path: WebsiteTypes$FrontendWebsite.createAssetPath(headerAssets),
      text: WebsiteTypes$FrontendWebsite.createTitle("hamburger logo"),
      desc: undefined,
      class_name: "hamburger-logo"
    }
  };
  var menuAssets = {
    TAG: /* InfoIconWithStyle */3,
    _0: {
      src_path: WebsiteTypes$FrontendWebsite.createAssetPath(uiAssets),
      text: WebsiteTypes$FrontendWebsite.createTitle(""),
      class_name: WebsiteTypes$FrontendWebsite.createAssetPath("menu-arrow")
    }
  };
  return {
          hamburgerInfo: hamburgerInfo,
          menuAssets: menuAssets
        };
}

var title_404 = "404-page";

var meta_description_404 = "You have landed to 404 page. Please check the url or click on redirect to home";

var title_home_uae = "Pickup Truck Rental | Movers & Delivery Service in UAE";

var title_home_bd = "Pickup Truck Rental | Movers & Delivery Service in Bangladesh";

var title_home_tr = "Pickup Truck Rental | Movers & Delivery Service in Turkiye";

var meta_description_home = "Transport cargo, furniture and shift houses starting from just 100 AED. Get local delivery, parcel pickup. Deliver anything starting from flowers to cake & groceries.";

var title_driver_partners = "Partner with us! Attach your pickup truck with Porter.";

var meta_description_driver_partners = "Join Porter by attaching your commercial pickup & canter vehicle and get business opportunities across Dubai & other emirates. Earn money & be your own boss.";

var title_about_us = "The Porter Journey - Understand more about the delivery app";

var meta_description_about_us_uae = "Porter is on a journey to build UAE's Largest Marketplace for Intracity Logistics | Revolutionizing one delivery at a time";

var title_terms_of_service = "Porter - Terms and Conditions";

var meta_description_terms_of_service = "In order to use the Porter's services and anything associated with Porter, you must agree to our Terms and Conditions. You can find all details here.";

var title_partner_terms_of_service = "Partner terms of service - for attaching vehicle with porter";

var meta_partner_description_terms_of_service = "Porter - Partner terms of service - for attaching vehicle with porter";

var title_privacy_policy = "Porter - Privacy Policy";

var meta_description_privacy_policy = "Porter values the privacy of individuals, partners, companies and whosoever uses our application and related services. Read our full Privacy Policy here.";

var title_refund_policy = "Porter - Refund Policy";

var meta_description_refund_policy = "Porter - Refund Policy";

var title_spot_faq = "Get answers to FAQs on Porter's Logistics Services";

var meta_description_spot_faq = "Get answers for all your questions. From how to book pickup trucks for moving and finding delivery charges to attaching pickup trucks. We have answered it all here.";

var title_support = "Need Help? Reach out to our expert team.";

var meta_description_support = "Contact our 24*7 support to get help for any of the Porter's delivery & logistic service? ";

var title_offers = "Porter Offers";

var meta_description_offers = "Porter Offers";

var title_services = "";

var meta_description_services = "";

var title_courier = "The Most Reliable Courier Service in UAE";

var meta_description_courier = "Ship anything, anywhere, anytime with the best courier service in UAE. Get started at AED 9 to send your parcels across UAE";

var title_termsOfServiceDubaiBranch = "";

var meta_termsOfServiceDubaiBranch = "";

var title_fare_estimate_uae = "Pickup Truck Rental | Movers & Delivery Service in UAE";

var title_fare_estimate_bd = "Pickup Truck Rental | Movers & Delivery Service in Bangladesh";

var title_fare_estimate_tr = "Pickup Truck Rental | Movers & Delivery Service in Turkiye";

var meta_description_fare_estimate = "Transport cargo, furniture and shift houses starting from just 100 AED. Get local delivery, parcel pickup. Deliver anything starting from flowers to cake & groceries.";

var headTags_support = {
  title: title_support,
  meta_description: meta_description_support,
  link: /* [] */0,
  local_data: /* [] */0
};

var headTags_spotFaq = {
  title: title_spot_faq,
  meta_description: meta_description_spot_faq,
  link: /* [] */0,
  local_data: /* [] */0
};

var headTags_404 = {
  title: title_404,
  meta_description: meta_description_404,
  link: /* [] */0,
  local_data: /* [] */0
};

function headTags_Home(country) {
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp = title_home_uae;
        break;
    case /* BD */1 :
        tmp = title_home_bd;
        break;
    case /* TR */2 :
        tmp = title_home_tr;
        break;
    
  }
  return {
          title: tmp,
          meta_description: meta_description_home,
          link: /* [] */0,
          local_data: /* [] */0
        };
}

function headTags_TrackLiveTrip(country) {
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp = title_home_uae;
        break;
    case /* BD */1 :
        tmp = title_home_bd;
        break;
    case /* TR */2 :
        tmp = title_home_tr;
        break;
    
  }
  return {
          title: tmp,
          meta_description: meta_description_home,
          link: /* [] */0,
          local_data: /* [] */0
        };
}

var headTags_DriverPartners = {
  title: title_driver_partners,
  meta_description: meta_description_driver_partners,
  link: /* [] */0,
  local_data: /* [] */0
};

function headTags_AboutUs(country) {
  return {
          title: title_about_us,
          meta_description: meta_description_about_us_uae,
          link: /* [] */0,
          local_data: /* [] */0
        };
}

var headTags_TermsOfService = {
  title: title_terms_of_service,
  meta_description: meta_description_terms_of_service,
  link: /* [] */0,
  local_data: /* [] */0
};

var headTags_PartnerTermsOfService = {
  title: title_partner_terms_of_service,
  meta_description: meta_partner_description_terms_of_service,
  link: /* [] */0,
  local_data: /* [] */0
};

var headTags_PrivacyPolicy = {
  title: title_privacy_policy,
  meta_description: meta_description_privacy_policy,
  link: /* [] */0,
  local_data: /* [] */0
};

var headTags_RefundPolicy = {
  title: title_refund_policy,
  meta_description: meta_description_refund_policy,
  link: /* [] */0,
  local_data: /* [] */0
};

function headTags_fareEstimateResult(country) {
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp = title_fare_estimate_uae;
        break;
    case /* BD */1 :
        tmp = title_fare_estimate_bd;
        break;
    case /* TR */2 :
        tmp = title_fare_estimate_tr;
        break;
    
  }
  return {
          title: tmp,
          meta_description: meta_description_fare_estimate,
          link: /* [] */0,
          local_data: /* [] */0
        };
}

var headTags_offers = {
  title: title_offers,
  meta_description: meta_description_offers,
  link: /* [] */0,
  local_data: /* [] */0
};

var headTags_services = {
  title: title_services,
  meta_description: meta_description_services,
  link: /* [] */0,
  local_data: /* [] */0
};

var headTags_courier = {
  title: title_courier,
  meta_description: meta_description_courier,
  link: /* [] */0,
  local_data: /* [] */0
};

var headTags_termsOfServiceDubaiBranch = {
  title: title_termsOfServiceDubaiBranch,
  meta_description: meta_termsOfServiceDubaiBranch,
  link: /* [] */0,
  local_data: /* [] */0
};

var stickyGaAction_uae = "Sticky Bottom App Download Click UAE";

var stickyGaAction = "Sticky Bottom App Download Click Banglades";

var appUrl = "https://porter.ae/app";

var partnerLocationRefreshInterval = 30000;

var info404 = {
  title: "404",
  description: "Page not found. Please redirect to home.",
  content: /* [] */0
};

var meta_description_about_us_bd = "Porter is on a journey to build Bangladesh's Largest Marketplace for Intracity Logistics | Revolutionizing one delivery at a time";

var ga_path_track_live_trip = "/track_live_trip";

var ga_path_home = "/";

var ga_path_driver_partners = "/partners";

var ga_path_about_us = "/about-us";

var ga_path_terms_of_service = "/terms-of-service";

var ga_path_terms_and_conditions = "/terms-and-conditions";

var ga_path_partner_terms_of_service = "/terms-of-service/partners";

var ga_path_terms_of_service_dubai_branch = "/dubai-branch/terms-of-service";

var ga_path_refund_policy = "/refund-policy";

var ga_path_services_page = "/services";

var ga_path_courier_page = "/courier";

var ga_title_home = "Home";

var ga_title_track_live_trip = "Track Live Trip";

var ga_title_driver_partners = "Partner";

var ga_title_about_us = "About Us";

var ga_title_terms_of_service = "Terms of Service";

var ga_title_terms_and_conditons = "Terms and Conditions";

var ga_title_partner_terms_of_service = "Partner Terms of Service";

var ga_title_terms_of_service_dubai_branch = "Terms of Service - Porter DMCC Dubai Branch";

var ga_title_refund_policy = "Refund Policy";

var ga_title_services_page = "Services Page";

var ga_title_courier_page = "Courier Page";

var ga_path_spot_faq = "/spot-faq";

var ga_title_spot_faq = "Spot Faqs";

var ga_path_support = "/support";

var ga_title_support = "Support";

var ga_path_offers = "/offers";

var ga_title_offers = "Porter Offers";

var ga_path_fare_estimate_result = "/fare-estimate";

var ga_title_fare_estimate_result = "Fare Estimate";

var ga_title_404 = "Page Not Found";

export {
  headerAssets ,
  uiAssets ,
  driverIcon ,
  isd_code ,
  stickyGaAction_uae ,
  stickyGaAction ,
  appUrl ,
  publishStickyGAEvents ,
  get_utm_param_with_value ,
  partnerLocationRefreshInterval ,
  cityTempoInfo ,
  info404 ,
  driverPartnersInfo ,
  supportInfo ,
  aboutUsInfo ,
  privacyPolicyInfo ,
  refundPolicyInfo ,
  termsOfServiceInfo ,
  driveWithUsInfo ,
  link404 ,
  Desktop ,
  Mobile ,
  title_404 ,
  meta_description_404 ,
  title_home_uae ,
  title_home_bd ,
  title_home_tr ,
  meta_description_home ,
  title_driver_partners ,
  meta_description_driver_partners ,
  title_about_us ,
  meta_description_about_us_uae ,
  meta_description_about_us_bd ,
  title_terms_of_service ,
  meta_description_terms_of_service ,
  title_partner_terms_of_service ,
  meta_partner_description_terms_of_service ,
  title_privacy_policy ,
  meta_description_privacy_policy ,
  title_refund_policy ,
  meta_description_refund_policy ,
  title_spot_faq ,
  meta_description_spot_faq ,
  title_support ,
  meta_description_support ,
  title_offers ,
  meta_description_offers ,
  title_services ,
  meta_description_services ,
  title_courier ,
  meta_description_courier ,
  title_termsOfServiceDubaiBranch ,
  meta_termsOfServiceDubaiBranch ,
  title_fare_estimate_uae ,
  title_fare_estimate_bd ,
  title_fare_estimate_tr ,
  meta_description_fare_estimate ,
  headTags_support ,
  headTags_spotFaq ,
  headTags_404 ,
  headTags_Home ,
  headTags_TrackLiveTrip ,
  headTags_DriverPartners ,
  headTags_AboutUs ,
  headTags_TermsOfService ,
  headTags_PartnerTermsOfService ,
  headTags_PrivacyPolicy ,
  headTags_RefundPolicy ,
  headTags_fareEstimateResult ,
  headTags_offers ,
  headTags_services ,
  headTags_courier ,
  headTags_termsOfServiceDubaiBranch ,
  ga_path_track_live_trip ,
  ga_path_home ,
  ga_path_driver_partners ,
  ga_path_about_us ,
  ga_path_terms_of_service ,
  ga_path_terms_and_conditions ,
  ga_path_partner_terms_of_service ,
  ga_path_terms_of_service_dubai_branch ,
  ga_path_refund_policy ,
  ga_path_services_page ,
  ga_path_courier_page ,
  ga_title_home ,
  ga_title_track_live_trip ,
  ga_title_driver_partners ,
  ga_title_about_us ,
  ga_title_terms_of_service ,
  ga_title_terms_and_conditons ,
  ga_title_partner_terms_of_service ,
  ga_title_terms_of_service_dubai_branch ,
  ga_title_refund_policy ,
  ga_title_services_page ,
  ga_title_courier_page ,
  ga_path_spot_faq ,
  ga_title_spot_faq ,
  ga_path_support ,
  ga_title_support ,
  ga_path_offers ,
  ga_title_offers ,
  ga_path_fare_estimate_result ,
  ga_title_fare_estimate_result ,
  ga_title_404 ,
  
}
/* headerAssets Not a pure module */
