// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Row$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/custom/layout-components/Row.bs.js";
import * as Button$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/buttons/Button.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as ModalContext$FrontendWebsite from "../../../../view/modals/ModalContext.bs.js";
import * as RoutesConfig$FrontendWebsite from "../../../routing/RoutesConfig.bs.js";
import * as CookieConsent_TR$FrontendWebsite from "../../../../utils/languang_and_content/tr/CookieConsent_TR.bs.js";
import * as CookieConsentHelper$FrontendWebsite from "../../utils/CookieConsentHelper.bs.js";

function CookieConsentCmp(Props) {
  var setConsentStatus = Props.setConsentStatus;
  var isDesktop = Props.isDesktop;
  var modalContext = ModalContext$FrontendWebsite.useModalContext(undefined);
  var match = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content;
  switch (match.TAG | 0) {
    case /* UAE */0 :
    case /* BD */1 :
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "CookieConsentCmp.res",
                4,
                56
              ],
              Error: new Error()
            };
    case /* TR */2 :
        Content = match._0 ? CookieConsent_TR$FrontendWebsite.TR : CookieConsent_TR$FrontendWebsite.English;
        break;
    
  }
  return React.createElement("div", {
              className: "c-cookie-consent-setting " + (
                isDesktop ? "" : "mobile"
              )
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "title"
                    }, Content.consentSettingTitle), React.createElement("div", {
                      className: "description"
                    }, Content.consentSettingDesc, React.createElement("a", {
                          className: "link-text",
                          href: RoutesConfig$FrontendWebsite.privacyPolicyUrl
                        }, "here"))), React.createElement(Row$Catalyst.make, {
                  children: null
                }, React.createElement(Button$Catalyst.make, {
                      text: Content.adjustCoookieSettingBtn,
                      cb: (function (_event) {
                          Curry._1(setConsentStatus, (function (param) {
                                  return true;
                                }));
                          return Curry._1(modalContext.toggleModal, {
                                      TAG: /* CookieManagement */1,
                                      _0: setConsentStatus
                                    });
                        }),
                      className: "secondary-btn submit-btn "
                    }), React.createElement(Button$Catalyst.make, {
                      text: Content.acceptAllBtn,
                      cb: (function (_event) {
                          Curry._1(setConsentStatus, (function (param) {
                                  return true;
                                }));
                          return CookieConsentHelper$FrontendWebsite.acceptAll(undefined);
                        }),
                      className: "primary-btn submit-btn"
                    })));
}

var make = CookieConsentCmp;

export {
  make ,
  
}
/* react Not a pure module */
