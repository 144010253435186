// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Home_BD$FrontendWebsite from "./Home_BD.bs.js";
import * as Vehicle_BD$FrontendWebsite from "./Vehicle_BD.bs.js";
import * as Navigation_BD$FrontendWebsite from "./Navigation_BD.bs.js";
import * as TrackOrder_BD$FrontendWebsite from "./TrackOrder_BD.bs.js";

var English_Home = {
  Select: Home_BD$FrontendWebsite.English.Select,
  heading: Home_BD$FrontendWebsite.English.heading,
  select_city_label: Home_BD$FrontendWebsite.English.select_city_label,
  driverPartnerHeading: Home_BD$FrontendWebsite.English.driverPartnerHeading,
  driverPartnerHeadingDesc: Home_BD$FrontendWebsite.English.driverPartnerHeadingDesc,
  driverPartnerJoinUsTitle: Home_BD$FrontendWebsite.English.driverPartnerJoinUsTitle,
  navbar_title_1: Home_BD$FrontendWebsite.English.navbar_title_1,
  navbar_title_2: Home_BD$FrontendWebsite.English.navbar_title_2,
  form_heading_1: Home_BD$FrontendWebsite.English.form_heading_1,
  form_heading_2: Home_BD$FrontendWebsite.English.form_heading_2,
  form_heading_3: Home_BD$FrontendWebsite.English.form_heading_3,
  form_heading_4: Home_BD$FrontendWebsite.English.form_heading_4,
  form_heading_5: Home_BD$FrontendWebsite.English.form_heading_5,
  form_heading_6: Home_BD$FrontendWebsite.English.form_heading_6,
  form_heading_7: Home_BD$FrontendWebsite.English.form_heading_7,
  form_button_title: Home_BD$FrontendWebsite.English.form_button_title,
  form_consent_box_title: Home_BD$FrontendWebsite.English.form_consent_box_title,
  about_us_link_text: Home_BD$FrontendWebsite.English.about_us_link_text,
  privacy_policy_link_text: Home_BD$FrontendWebsite.English.privacy_policy_link_text,
  terms_of_service_link_text: Home_BD$FrontendWebsite.English.terms_of_service_link_text,
  terms_of_service_url: Home_BD$FrontendWebsite.English.terms_of_service_url,
  refund_policy_link_text: Home_BD$FrontendWebsite.English.refund_policy_link_text,
  download_porter_button_label: Home_BD$FrontendWebsite.English.download_porter_button_label,
  pickup_address_placeholder: Home_BD$FrontendWebsite.English.pickup_address_placeholder,
  dropoff_address_placeholder: Home_BD$FrontendWebsite.English.dropoff_address_placeholder,
  get_estimate_title: Home_BD$FrontendWebsite.English.get_estimate_title,
  footer_title: Home_BD$FrontendWebsite.English.footer_title,
  optional_placeholder: Home_BD$FrontendWebsite.English.optional_placeholder,
  companyCopyrightText: Home_BD$FrontendWebsite.English.companyCopyrightText,
  companyCinNo: Home_BD$FrontendWebsite.English.companyCinNo,
  newCompanyCopyrightText: Home_BD$FrontendWebsite.English.newCompanyCopyrightText,
  newCompanyCinNo: Home_BD$FrontendWebsite.English.newCompanyCinNo
};

var English = {
  Home: English_Home,
  TrackOrder: TrackOrder_BD$FrontendWebsite.English,
  Vehicle: Vehicle_BD$FrontendWebsite.English,
  Navigation: Navigation_BD$FrontendWebsite.English
};

var BD_Home = {
  Select: Home_BD$FrontendWebsite.BD.Select,
  heading: Home_BD$FrontendWebsite.BD.heading,
  select_city_label: Home_BD$FrontendWebsite.BD.select_city_label,
  driverPartnerHeading: Home_BD$FrontendWebsite.BD.driverPartnerHeading,
  driverPartnerHeadingDesc: Home_BD$FrontendWebsite.BD.driverPartnerHeadingDesc,
  driverPartnerJoinUsTitle: Home_BD$FrontendWebsite.BD.driverPartnerJoinUsTitle,
  navbar_title_1: Home_BD$FrontendWebsite.BD.navbar_title_1,
  navbar_title_2: Home_BD$FrontendWebsite.BD.navbar_title_2,
  form_heading_1: Home_BD$FrontendWebsite.BD.form_heading_1,
  form_heading_2: Home_BD$FrontendWebsite.BD.form_heading_2,
  form_heading_3: Home_BD$FrontendWebsite.BD.form_heading_3,
  form_heading_4: Home_BD$FrontendWebsite.BD.form_heading_4,
  form_heading_5: Home_BD$FrontendWebsite.BD.form_heading_5,
  form_heading_6: Home_BD$FrontendWebsite.BD.form_heading_6,
  form_heading_7: Home_BD$FrontendWebsite.BD.form_heading_7,
  form_button_title: Home_BD$FrontendWebsite.BD.form_button_title,
  form_consent_box_title: Home_BD$FrontendWebsite.BD.form_consent_box_title,
  about_us_link_text: Home_BD$FrontendWebsite.BD.about_us_link_text,
  privacy_policy_link_text: Home_BD$FrontendWebsite.BD.privacy_policy_link_text,
  terms_of_service_link_text: Home_BD$FrontendWebsite.BD.terms_of_service_link_text,
  terms_of_service_url: Home_BD$FrontendWebsite.BD.terms_of_service_url,
  refund_policy_link_text: Home_BD$FrontendWebsite.BD.refund_policy_link_text,
  download_porter_button_label: Home_BD$FrontendWebsite.BD.download_porter_button_label,
  pickup_address_placeholder: Home_BD$FrontendWebsite.BD.pickup_address_placeholder,
  dropoff_address_placeholder: Home_BD$FrontendWebsite.BD.dropoff_address_placeholder,
  get_estimate_title: Home_BD$FrontendWebsite.BD.get_estimate_title,
  footer_title: Home_BD$FrontendWebsite.BD.footer_title,
  optional_placeholder: Home_BD$FrontendWebsite.BD.optional_placeholder,
  companyCopyrightText: Home_BD$FrontendWebsite.BD.companyCopyrightText,
  companyCinNo: Home_BD$FrontendWebsite.BD.companyCinNo,
  newCompanyCopyrightText: Home_BD$FrontendWebsite.BD.newCompanyCopyrightText,
  newCompanyCinNo: Home_BD$FrontendWebsite.BD.newCompanyCinNo
};

var BD_Vehicle = {
  getVehicleData: Vehicle_BD$FrontendWebsite.BD.getVehicleData,
  canter_3tonne_title: Vehicle_BD$FrontendWebsite.BD.canter_3tonne_title,
  pickup_1tonne_title: Vehicle_BD$FrontendWebsite.BD.pickup_1tonne_title,
  two_wheeler_title: Vehicle_BD$FrontendWebsite.BD.two_wheeler_title,
  porter_offers_sub_title: Vehicle_BD$FrontendWebsite.BD.porter_offers_sub_title
};

var BD = {
  Home: BD_Home,
  TrackOrder: TrackOrder_BD$FrontendWebsite.BD,
  Vehicle: BD_Vehicle,
  Navigation: Navigation_BD$FrontendWebsite.BD
};

export {
  English ,
  BD ,
  
}
/* Home_BD-FrontendWebsite Not a pure module */
