// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";

var IstanbulEnglish_canter_3tonne_title = "";

var IstanbulEnglish_canter_3tonne_meta_description = "";

var IstanbulEnglish_two_wheeler_title = "";

var IstanbulEnglish_two_wheeler_meta_description = "";

var IstanbulEnglish_pickup_1tonne_title = "";

var IstanbulEnglish_pickup_1tonne_meta_description = "";

var IstanbulEnglish_compact_title = "";

var IstanbulEnglish_compact_meta_description = "";

var IstanbulEnglish_courier_title = "";

var IstanbulEnglish_courier_meta_description = "";

var IstanbulEnglish = {
  canter_3tonne_title: IstanbulEnglish_canter_3tonne_title,
  canter_3tonne_meta_description: IstanbulEnglish_canter_3tonne_meta_description,
  two_wheeler_title: IstanbulEnglish_two_wheeler_title,
  two_wheeler_meta_description: IstanbulEnglish_two_wheeler_meta_description,
  pickup_1tonne_title: IstanbulEnglish_pickup_1tonne_title,
  pickup_1tonne_meta_description: IstanbulEnglish_pickup_1tonne_meta_description,
  compact_title: IstanbulEnglish_compact_title,
  compact_meta_description: IstanbulEnglish_compact_meta_description,
  courier_title: IstanbulEnglish_courier_title,
  courier_meta_description: IstanbulEnglish_courier_meta_description
};

var IstanbulTR_canter_3tonne_title = "";

var IstanbulTR_canter_3tonne_meta_description = "";

var IstanbulTR_two_wheeler_title = "";

var IstanbulTR_two_wheeler_meta_description = "";

var IstanbulTR_pickup_1tonne_title = "";

var IstanbulTR_pickup_1tonne_meta_description = "";

var IstanbulTR_compact_title = "";

var IstanbulTR_compact_meta_description = "";

var IstanbulTR_courier_title = "";

var IstanbulTR_courier_meta_description = "";

var IstanbulTR = {
  canter_3tonne_title: IstanbulTR_canter_3tonne_title,
  canter_3tonne_meta_description: IstanbulTR_canter_3tonne_meta_description,
  two_wheeler_title: IstanbulTR_two_wheeler_title,
  two_wheeler_meta_description: IstanbulTR_two_wheeler_meta_description,
  pickup_1tonne_title: IstanbulTR_pickup_1tonne_title,
  pickup_1tonne_meta_description: IstanbulTR_pickup_1tonne_meta_description,
  compact_title: IstanbulTR_compact_title,
  compact_meta_description: IstanbulTR_compact_meta_description,
  courier_title: IstanbulTR_courier_title,
  courier_meta_description: IstanbulTR_courier_meta_description
};

function getVehicleData(cities) {
  return IstanbulEnglish;
}

function canter_3tonne_title(name, city) {
  return "Reliable Truck " + name + " Rentals in " + $$String.capitalize_ascii(city);
}

function pickup_1tonne_title(name, city) {
  return "Rent Affordable " + name + " Pickup Trucks in " + $$String.capitalize_ascii(city);
}

function two_wheeler_title(name, city) {
  return "Quick & On-time Bike Delivery Service in " + $$String.capitalize_ascii(city);
}

var English_porter_offers_sub_title = "Exciting offers coming soon";

var English = {
  getVehicleData: getVehicleData,
  canter_3tonne_title: canter_3tonne_title,
  pickup_1tonne_title: pickup_1tonne_title,
  two_wheeler_title: two_wheeler_title,
  porter_offers_sub_title: English_porter_offers_sub_title
};

function getVehicleData$1(cities) {
  return IstanbulTR;
}

function canter_3tonne_title$1(name, city) {
  return "Reliable Truck " + name + " Rentals in " + $$String.capitalize_ascii(city);
}

function pickup_1tonne_title$1(name, city) {
  return "Rent Affordable " + name + " Pickup Trucks in " + $$String.capitalize_ascii(city);
}

function two_wheeler_title$1(name, city) {
  return "Quick & On-time Bike Delivery Service in " + $$String.capitalize_ascii(city);
}

var TR_porter_offers_sub_title = "Yakında heyecan verici teklifler geliyor";

var TR = {
  getVehicleData: getVehicleData$1,
  canter_3tonne_title: canter_3tonne_title$1,
  pickup_1tonne_title: pickup_1tonne_title$1,
  two_wheeler_title: two_wheeler_title$1,
  porter_offers_sub_title: TR_porter_offers_sub_title
};

export {
  IstanbulEnglish ,
  IstanbulTR ,
  English ,
  TR ,
  
}
/* No side effect */
