// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../Config.bs.js";

var baseRouterUrl = Config$FrontendWebsite.Env.getFEUrl(undefined);

var aboutUsUrl = baseRouterUrl + "/about-us";

var privacyPolicyUrl = baseRouterUrl + "/privacy-policy";

var refundPolicyUrl = baseRouterUrl + "/refund-policy";

var spotFaqUrl = baseRouterUrl + "/spot-faq";

var serviceUrl = baseRouterUrl + "/services";

var homeUrl = "/";

var partnersUrl = "/partners";

var supportUrl = "/support";

export {
  baseRouterUrl ,
  homeUrl ,
  partnersUrl ,
  supportUrl ,
  aboutUsUrl ,
  privacyPolicyUrl ,
  refundPolicyUrl ,
  spotFaqUrl ,
  serviceUrl ,
  
}
/* baseRouterUrl Not a pure module */
