// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";

var Sharjah_compact_title = "Book Porter Compact in Sharjah - Best Delivery Van Solution";

var Sharjah_compact_meta_description = "Looking for the ultimate delivery van solution in Sharjah? Book Porter Compact now for efficiency and reliability.";

var Sharjah_courier_title = "Instant Delivery of Goods with Porter Courier Services in Sharjah";

var Sharjah_courier_meta_description = "Swift delivery of goods in Sharjah with Porter Courier Services. Enjoy hassle-free transportation for personal or professional parcels right to your doorstep.";

var Sharjah = {
  canter_3tonne_title: "Hire Affordable 3 Ton Pickup Truck in Sharjah - Get Quote !",
  canter_3tonne_meta_description: "Book 3 Ton Pickup Truck Rental to simplify your logistics needs \xe2\x9c\xafPorter Canter Truck Rentals ensures efficient transport and hassle-free gods transport",
  two_wheeler_title: "Affordable Delivery Services with Bike in Sharjah- Book Now!",
  two_wheeler_meta_description: "Experience quick and reliable Delivery Service via Bike  in Sharjah \xe2\x9c\xaf  Explore our instant and affordable delivery options today.",
  pickup_1tonne_title: "Efficient 1 -Ton Pickup Rental in Sharjah - Book Now!",
  pickup_1tonne_meta_description: "Rent a 1 Ton pickup truck in Sharjah? Look no further - Book 1 Tone Pickup Truck Rental for Transport Needs \xe2\x9c\xafReliable & Affordable Delivery Services",
  compact_title: Sharjah_compact_title,
  compact_meta_description: Sharjah_compact_meta_description,
  courier_title: Sharjah_courier_title,
  courier_meta_description: Sharjah_courier_meta_description
};

var Dubai_compact_title = "Book Porter Compact in Dubai - Best Delivery Van Solution";

var Dubai_compact_meta_description = "Looking for the ultimate delivery van solution in Dubai? Book Porter Compact now for efficiency and reliability.";

var Dubai_courier_title = "Hassle-free Courier Services in Dubai";

var Dubai_courier_meta_description = "Choose Porter Courier Services to send anything, anytime, anywhere across Dubai, get started at AED 9  to send your parcels across Dubai";

var Dubai = {
  canter_3tonne_title: "Hire Affordable 3 Ton Pickup in Dubai - Book Today!",
  canter_3tonne_meta_description: "Simplify logistics in Dubai with our 3 Ton Pickup Truck Rental \xe2\x9c\xaf Easy Canter Truck Booking ensures efficient transport and hassle-free delivery",
  two_wheeler_title: "Swift & Affordable Package Delivery Service by Bike in Dubai - Book Now!",
  two_wheeler_meta_description: "Cost-effective package delivery services in Dubai with two wheeler. Explore our instant and affordable package delivery options today.",
  pickup_1tonne_title: "Efficient 1 -Ton Pickup Truck Rental in Dubai - Get Quote Now!",
  pickup_1tonne_meta_description: "Book 1 Ton Pickup Truck Rental services in Dubai \xe2\x9c\xaf Explore our Instant and affordable options for your good transport needs",
  compact_title: Dubai_compact_title,
  compact_meta_description: Dubai_compact_meta_description,
  courier_title: Dubai_courier_title,
  courier_meta_description: Dubai_courier_meta_description
};

function getVehicleData(cities) {
  if (cities !== 1) {
    return Dubai;
  } else {
    return Sharjah;
  }
}

function canter_3tonne_title(name, city) {
  return "Rent " + name + " in " + $$String.capitalize_ascii(city) + " for delivery service";
}

function pickup_1tonne_title(name, city) {
  return "Rent " + name + " delivery service in " + $$String.capitalize_ascii(city) + " for Instant and Affordable Deliveries";
}

function two_wheeler_title(name, city) {
  return "Rent " + name + " in " + $$String.capitalize_ascii(city) + " for  Instant Deliveries/ Small Packages";
}

var English = {
  getVehicleData: getVehicleData,
  canter_3tonne_title: canter_3tonne_title,
  pickup_1tonne_title: pickup_1tonne_title,
  two_wheeler_title: two_wheeler_title,
  porter_offers_sub_title: "Exciting offers coming soon"
};

export {
  Sharjah ,
  Dubai ,
  English ,
  
}
/* No side effect */
